import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion } from "framer-motion";
import style from "./Header.module.scss";
import { ReactComponent as MenuIcon } from "../.././img/menu.svg";
import { ReactComponent as CloseIcon } from "../.././img/close.svg";
import { RotatingText } from "rotating-text";
import "rotating-text/dist/index.css";

const { navigator } = window;

const Header = ({ onLanguageChange }) => {
  const { t, i18n } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const browserLanguage = navigator.language.toLowerCase().split(/[_-]+/)[0];
    if (browserLanguage === "ua" || browserLanguage === "ru") {
      i18n.changeLanguage("ua");
    } else {
      i18n.changeLanguage("en");
    }
  }, [i18n]);

  const changeLanguage = () => {
    const currentLanguage = i18n.language;
    const oppositeLanguage = currentLanguage === "en" ? "ua" : "en";
    onLanguageChange();
    setTimeout(() => {
      i18n.changeLanguage(oppositeLanguage);
    }, 500);
  };

  const navItems = [
    { label: t("about"), target: "about" },
    { label: t("services"), target: "services" },
    { label: t("works"), target: "works" },
    { label: t("contact"), target: "contact" },
  ];

  const menuAnimation = {
    initial: { opacity: 0, x: 50 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -50, transition: { duration: 0.3 } },
  };

  const menu = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: { duration: 0.2 },
    },
  };

  return (
    <div className="container">
      <Helmet>
        <title>{t("title")}</title>
        <meta name="description" content={t("description")} />
      </Helmet>
      <nav className={style.nav}>
        <div className={style.nav__logo}>
          <Link to="hero" smooth={true} offset={50} duration={800}>
            <RotatingText
              text="Volodymyr&nbsp;Hrabchuk"
              stagger={0.05}
              timing={0.2}
            />
          </Link>
        </div>
        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              className={`${style.menu} ${isMenuOpen ? style.open : ""}`}
              {...menuAnimation}
            >
              <motion.ul className={style.nav__list}>
                {navItems.map((item, index) => (
                  <motion.li
                    className={style.nav__list_item}
                    key={index}
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 + index * 0.1 }}
                  >
                    <Link
                      to={item.target}
                      smooth={true}
                      offset={50}
                      duration={800}
                      onClick={closeMenu}
                    >
                      {item.label}
                    </Link>
                  </motion.li>
                ))}
              </motion.ul>
              <div className={style.language}>
                <button
                  className={style.language__item}
                  onClick={changeLanguage}
                >
                  {i18n.language === "en" ? "UA" : "EN"}
                </button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        {!isMenuOpen && (
          <motion.div
            className={style.menu}
            variants={menu}
            initial="initial"
            animate="animate"
          >
            <motion.ul className={style.nav__list}>
              {navItems.map((item, index) => (
                <motion.li
                  className={style.nav__list_item}
                  key={index}
                  variants={{
                    initial: {
                      opacity: 0,
                      y: -100,
                    },
                    animate: {
                      opacity: 1,
                      y: 0,
                      transition: {
                        delay: index * 0.1,
                      },
                    },
                  }}
                >
                  <Link
                    to={item.target}
                    smooth={true}
                    offset={50}
                    duration={800}
                    onClick={closeMenu}
                  >
                    {item.label}
                  </Link>
                </motion.li>
              ))}
            </motion.ul>
            <div className={style.language} onClick={changeLanguage}>
              <button className={style.language__item}>
                {i18n.language === "en" ? "UA" : "EN"}
              </button>
            </div>
          </motion.div>
        )}

        {isMenuOpen ? (
          <CloseIcon className={style.close_icon} onClick={toggleMenu} />
        ) : (
          <MenuIcon className={style.menu_icon} onClick={toggleMenu} />
        )}
      </nav>
    </div>
  );
};

export default Header;

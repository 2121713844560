import React, { useState, useEffect } from "react";
import About from "../components/About/About";
import Contact from "../components/Contact/Contact";
import Hero from "../components/Hero/Hero";
import Marque from "../components/Marquee/Marque";
import Portfolio from "../components/Portfolio/Portflio";
import Services from "../components/Works/Services";
import Footer from "../components/Footer/Footer";
import TopButton from "../components/TopButton/TopButton";

const Main = ({ onLanguageChange }) => {
  const [showTopButton, setShowTopButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 200;
      const shouldShowButton = window.pageYOffset > scrollThreshold;
      setShowTopButton(shouldShowButton);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <>
      <Hero onLanguageChange={onLanguageChange} />
      <Marque />
      {showTopButton && <TopButton />}
      <About id="about" />
      <Services />
      <Portfolio />
      <Contact />
      <Footer />
    </>
  );
};

export default Main;

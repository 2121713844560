import style from "./footer.module.scss";
import { ReactComponent as Insta } from "../../img/insta.svg";
import { ReactComponent as Telegram } from "../../img/telegram.svg";

const Footer = () => {
  return (
    <div className="container">
      <div className={style.footer}>
        <div className={style.social}>
          <a href="https://www.instagram.com/fakepicture.bmp/" target="blank" aria-label="Instagram">
            <Insta className={style.social_icon} />
          </a>
          <a href="https://t.me/fakepicture_jpg" target="blank" aria-label="Telegram">
            <Telegram className={style.social_icon} />
          </a>
        </div>
        <p className={style.footer_text}>
          ©2023 / Volodymyr Hrabchuk / All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;

import style from "./contact.module.scss";
import { ReactComponent as ArrowRight } from "../../img/arr-right.svg";
import { useForm } from "react-hook-form";
import Schema from "../../schema/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { motion, useInView } from "framer-motion";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Reveal from "../Reveal";
import Modal from "../Modal/Modal";

const Contact = () => {
  const { t } = useTranslation();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(Schema()),
  });

  const onSubmit = async (data) => {
    try {
      await axios.post("/send", {
        name: data.name,
        email: data.email,
        comments: data.comment,
      });
      setIsFormSubmitted(true);
    } catch (error) {}
   reset();
  };

  const ref = useRef(null);

  const inView = useInView(ref, { once: true, threshold: 0.1 });

  const blockVariants = {
    visible: {
      transition: {
        staggerChildren: 0.4,
      },
    },
  };

  const buttonVariants = {
    hidden: {
      opacity: 0,
      scale: 0,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const formVariants = {
    hidden: {
      opacity: 0,
      y: 300,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    <div className="container">
      <Reveal>
        <h2 className={style.title} id="contact">
          {t("contact-me")}
        </h2>
      </Reveal>
      <div className={style.contact}>
        <motion.div
          className={style.contact__wrap}
          variants={blockVariants}
          initial="hidden"
          ref={ref}
          whileInView={inView ? "visible" : "hidden"}
          viewport={{ once: true }}
        >
          <motion.div
            className={style.contact__heading}
            variants={formVariants}
          >
            {t("contact-me-desc")}
          </motion.div>
          <motion.div className={style.contact__text} variants={formVariants}>
            {t("contact-me-desc-2")}
          </motion.div>
        </motion.div>
        <motion.form
          action=""
          className={style.contact__form}
          onSubmit={handleSubmit(onSubmit)}
          variants={blockVariants}
          initial="hidden"
          ref={ref}
          whileInView={inView ? "visible" : "hidden"}
          viewport={{ once: true }}
        >
          <motion.div className={style.block} variants={formVariants}>
            <input
              type="email"
              placeholder={t("email")}
              className={style.contact__form_input}
              {...register("email")}
            />
            {errors.email && (
              <span className={style.error}>{errors.email.message}</span>
            )}
          </motion.div>
          <motion.div className={style.block} variants={formVariants}>
            <input
              type="text"
              placeholder={t("name")}
              className={style.contact__form_input}
              {...register("name")}
            />
            {errors.name && (
              <span className={style.error}>{errors.name.message}</span>
            )}
          </motion.div>
          <motion.input
            type="text"
            name="comments"
            placeholder={t("message")}
            className={style.contact__form_input}
            variants={formVariants}
            {...register("comment")}
          />

          <motion.button
            type="submit"
            className={style.button}
            disabled={!isValid}
            variants={buttonVariants}
            initial="hidden"
            ref={ref}
            whileInView={inView ? "visible" : "hidden"}
            viewport={{ once: true }}
          >
            {t("button")}
            <div className={style.button_icon}>
              <ArrowRight className={style.button_icon_svg} />
            </div>
          </motion.button>
        </motion.form>
        {isFormSubmitted && <Modal />}
      </div>
    </div>
  );
};

export default Contact;

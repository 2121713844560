import style from "./about.module.scss";
import { useTranslation } from "react-i18next";
import Reveal from "../Reveal";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";

const About = () => {
  const { t } = useTranslation();
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);

  const inView1 = useInView(ref1, { rootMargin: "-200px 0px", once: true });
  const inView2 = useInView(ref2, { rootMargin: "-200px 0px", once: true });
  const inView3 = useInView(ref3, { rootMargin: "-200px 0px", once: true });
  const inView4 = useInView(ref4, { rootMargin: "-200px 0px", once: true });

  const blockVariants = {
    visible: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const elementVariants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    <div className={style.about} id="about">
      <div className={`container ${style.about}`}>
        <Reveal>
          <h2 className={style.title}>{t("about-me")}</h2>
        </Reveal>

        <div className={style.wrap}>
          <motion.div
            className={style.block}
            variants={blockVariants}
            initial="hidden"
            ref={ref1}
            whileInView={inView1 ? "visible" : "hidden"}
            viewport={{ once: true }}
          >
            <motion.h4 className={style.heading} variants={elementVariants}>
              {t("about-1")}
            </motion.h4>
            <motion.p className={style.number} variants={elementVariants}>
              20
            </motion.p>
            <motion.p className={style.desc} variants={elementVariants}>
              {t("about-1-desc")}
            </motion.p>
          </motion.div>
          <motion.div
            className={style.block}
            variants={blockVariants}
            initial="hidden"
            ref={ref2}
            whileInView={inView2 ? "visible" : "hidden"}
            viewport={{ once: true }}
          >
            <motion.h4 className={style.heading} variants={elementVariants}>
              {t("about-2")}
            </motion.h4>
            <motion.p className={style.number} variants={elementVariants}>
              10
            </motion.p>
            <motion.p className={style.desc} variants={elementVariants}>
              {t("about-2-desc")}
            </motion.p>
          </motion.div>
          <motion.div
            className={style.block}
            variants={blockVariants}
            initial="hidden"
            ref={ref3}
            whileInView={inView3 ? "visible" : "hidden"}
            viewport={{ once: true }}
          >
            <motion.h4 className={style.heading} variants={elementVariants}>
              {t("about-3")}
            </motion.h4>
            <motion.p className={style.number} variants={elementVariants}>
              95%
            </motion.p>
            <motion.p className={style.desc} variants={elementVariants}>
              {t("about-3-desc")}
            </motion.p>
          </motion.div>
        </div>
        <motion.div
          className={style.about_me}
          variants={blockVariants}
          initial="hidden"
          ref={ref4}
          whileInView={inView4 ? "visible" : "hidden"}
          viewport={{ once: true }}
        >
          <motion.h4 className={style.heading} variants={elementVariants}>
            {t("about-bottom")}
          </motion.h4>
          <motion.p className={style.desc} variants={elementVariants}>
            {t("about-bottom-desc")}
          </motion.p>
        </motion.div>
      </div>
    </div>
  );
};

export default About;

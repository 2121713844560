import Header from "../Header/Header";
import React, { useEffect, useRef } from "react";
import HeroStyle from "./hero.module.scss";
import { useTranslation } from "react-i18next";
import { ReactComponent as Arrow } from "../../img/arrow.svg";
import { Link } from "react-scroll";
import { motion, useAnimation } from "framer-motion";

const Hero = ({ onLanguageChange }) => {
  const { t } = useTranslation();

  const textRef = useRef(null);
  useEffect(() => {
    const text = textRef.current;
    const textContent = text.innerText;

    const letters = textContent.split("");
    const degreePerLetter = 360 / letters.length;

    text.innerHTML = letters
      .map((letter, index) => {
        const rotation = index * degreePerLetter;
        return `<span style="transform:rotate(${rotation}deg)">${letter}</span>`;
      })
      .join("");
  }, []);

  const controls = useAnimation();
  useEffect(() => {
    controls.start({
      x: 0,
      y: 0,
      transition: { duration: 1, ease: "easeInOut", delay: 0.3 },
    });
  }, [controls]);

  return (
    <div className={HeroStyle.hero} id="hero">
      <Header onLanguageChange={onLanguageChange} />
      <div className={HeroStyle.hero__content}>
        <motion.div
          className={HeroStyle.wrapper}
          initial={{ opacity: 0, rotateX: 150 }}
          animate={{ opacity: 1, rotateX: 0 }}
          transition={{
            ease: "easeInOut",
            duration: 1,
            delay: 0.4,
          }}
        >
          <Link to="contact" smooth={true} offset={50} duration={800}>
            <div className={HeroStyle.circle}>
              <Arrow className={HeroStyle.arrow} />
              <div className={HeroStyle.text} ref={textRef}>
                <p className="">&nbsp;Let's&nbsp;cooperate</p>
              </div>
            </div>
          </Link>
        </motion.div>
        <motion.h3
          className={HeroStyle.hero__quote}
          initial={{ y: -500 }}
          animate={controls}
          transition={{
            ease: "easeInOut",
            duration: 1,
            delay: 0.4,
          }}
        >
          &lt;{t("quotes")}/&gt;
        </motion.h3>
        <div className={HeroStyle.inner_wrap}>
          <motion.p
            className={HeroStyle.hero__text}
            dangerouslySetInnerHTML={{ __html: t("hero") }}
            initial={{ y: 500 }}
            animate={controls}
            transition={{
              ease: "easeInOut",
              duration: 1,
              delay: 0.4,
            }}
          />

          <motion.h1
            className={HeroStyle.hero__title}
            initial={{ y: 400 }}
            animate={controls}
            transition={{
              ease: "easeInOut",
              duration: 1,
              delay: 0.4,
            }}
          >
            Website Developing
          </motion.h1>
        </div>
        <div className={HeroStyle.inner_wrap_mobile}>
          <motion.p
            className={HeroStyle.hero__text}
            dangerouslySetInnerHTML={{ __html: t("hero") }}
            initial={{ y: 500 }}
            animate={controls}
            transition={{
              ease: "easeInOut",
              duration: 1,
              delay: 0.4,
            }}
          />
          <motion.h1
            className={HeroStyle.hero__title}
            initial={{ y: 800 }}
            animate={controls}
            transition={{
              ease: "easeInOut",
              duration: 1,
              delay: 0.4,
            }}
          >
            Website Developing
          </motion.h1>
        </div>
      </div>
    </div>
  );
};

export default Hero;

import style from "./portfolio.module.scss";
import { worksData } from "../Data/worksData";
import { ReactComponent as ArrowRight } from "../../img/arr-right.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Reveal from "../Reveal";

const Portfolio = () => {
  const { t } = useTranslation();

  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    setMousePosition({ x: e.clientX, y: e.clientY });
  };
  return (
    <div className={style.work} id="works">
      <div className={`container ${style.work}`}>
        <Reveal className="reveal">
          <h2 className={style.title}>{t("examples")}</h2>
        </Reveal>
        <div className={style.wrap} onMouseMove={handleMouseMove}>
          {worksData.map((item) => (
            <div className={style.block} key={item.id}>
              <img
                src={item.img}
                alt=""
                className={style.img}
                key={item.id}
                style={{
                  transform: `translate(${mousePosition.x - 70}px, ${
                    mousePosition.y - 450
                  }px)`,
                }}
              />
              <Reveal>
                <p className={style.desc}>
                  <a
                    href={item.link}
                    className={style.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.name}
                  </a>
                </p>
              </Reveal>
              <ArrowRight className={style.svg} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;

import barbershop from "../../img/barbershop-f.webp";
import brewery from "../../img/brewery-f.webp";
import coco from "../../img/coco-f.webp";
import food from "../../img/food-f.webp";
import hotel from "../../img/hotel-f.webp";
import numeroventi from "../../img/numeroventi-f.webp";
import photographer from "../../img/photographer-f.webp";

export const worksData = [
  {
    id: 1,
    name: "P&P Barbers",
    img: barbershop,
    link: "https://www.ppbarbers.com/",
  },
  {
    id: 2,
    name: "Flying Couch",
    img: brewery,
    link: "https://flyingcouch.dk/",
  },
  {
    id: 3,
    name: "ChefCoco",
    img: coco,
    link: "https://www.chefcoco.com/",
  },
  {
    id: 4,
    name: "Call Your Mother",
    img: food,
    link: "https://www.callyourmotherdeli.com/",
  },
  {
    id: 5,
    name: "NOFO HOTEL & WINE BAR",
    img: hotel,
    link: "https://nofohotel.se/",
  },
  {
    id: 6,
    name: "Numeroventi",
    img: numeroventi,
    link: "https://numeroventi.it/",
  },
  {
    id: 7,
    name: "Anthony Kurtz",
    img: photographer,
    link: "https://www.anthonykurtz.com/",
  },
];

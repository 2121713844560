import style from "./services.module.scss";
import { useTranslation } from "react-i18next";
import Reveal from "../Reveal";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";

const Services = () => {
  const { t } = useTranslation();
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);

  const inView1 = useInView(ref1, { once: true, threshold: 0.2 });
  const inView2 = useInView(ref2, { once: true, threshold: 0.2 });
  const inView3 = useInView(ref3, { once: true, threshold: 0.2 });
  const inView4 = useInView(ref4, { once: true, threshold: 0.2 });

  const blockVariants = {
    visible: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const elementVariants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    <div className="container">
      <Reveal>
        <h2 className={style.title} id="services">
          {t("service")}
        </h2>
      </Reveal>

      <div className={style.service}>
        <motion.div
          className={style.service_main}
          variants={blockVariants}
          initial="hidden"
          ref={ref1}
          whileInView={inView1 ? "visible" : "hidden"}
          viewport={{ once: true }}
        >
          <motion.h3 className={style.service_title} variants={elementVariants}>
            {t("service-1")}
          </motion.h3>
          <p className={style.symbol}>{"{/}"}</p>
          <motion.p className={style.service_descr} variants={elementVariants}>
            {t("service-1-desc")}
          </motion.p>
        </motion.div>
        <div className={style.service_wrapper}>
          <motion.div
            className={style.service_item}
            variants={blockVariants}
            initial="hidden"
            ref={ref2}
            whileInView={inView2 ? "visible" : "hidden"}
            viewport={{ once: true }}
          >
            <motion.h3
              className={style.service_title}
              variants={elementVariants}
            >
              {t("service-2")}
            </motion.h3>
            <motion.p
              className={style.service_descr}
              variants={elementVariants}
            >
              {t("service-2-desc")}
            </motion.p>
          </motion.div>
          <div className={style.service_inner_wrap}>
            <motion.div
              className={style.service_item}
              variants={blockVariants}
              initial="hidden"
              ref={ref3}
              whileInView={inView3 ? "visible" : "hidden"}
              viewport={{ once: true }}
            >
              <motion.h3
                className={style.service_title}
                variants={elementVariants}
              >
                {t("service-3")}
              </motion.h3>
              <motion.p
                className={style.service_descr}
                variants={elementVariants}
              >
                {t("service-3-desc")}
              </motion.p>
            </motion.div>
            <motion.div
              className={style.service_item}
              variants={blockVariants}
              initial="hidden"
              ref={ref4}
              whileInView={inView4 ? "visible" : "hidden"}
              viewport={{ once: true }}
            >
              <motion.h3
                className={style.service_title}
                variants={elementVariants}
              >
                {t("service-4")}
              </motion.h3>
              <motion.p
                className={style.service_descr}
                variants={elementVariants}
              >
                {t("service-4-desc")}
              </motion.p>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;

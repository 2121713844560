import Marquee from "react-fast-marquee";
import style from "./marquee.module.scss";
import { useTranslation } from "react-i18next";

const Marque = () => {
  const { t } = useTranslation();
  const items = [
    { id: "1", text: t("marq-1") },
    { id: "2", text: t("marq-2") },
    { id: "3", text: t("marq-3") },
    { id: "4", text: t("marq-4") },
  ];

  return (
    <>
      <Marquee gradient={false} className={style.block} speed={30}>
        {items.map(({ id, text }) => (
          <div key={id} className={style.block__text}>
            {text}
          </div>
        ))}
      </Marquee>
    </>
  );
};

export default Marque;

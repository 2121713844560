import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from "../.././img/close.svg";
import { AnimatePresence, motion } from "framer-motion";
import style from "./modal.module.scss";
import Check from "../Check";

const Modal = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);
  const modalRef = useRef(null);

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const modalAnimation = {
    initial: { opacity: 0, scale: 0.3 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.3, transition: { duration: 0.3 } },
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <div className={style.modal}>
          <motion.div
            className={style.modal__wrap}
            ref={modalRef}
            {...modalAnimation}
          >
            <CloseIcon className={style.svg} onClick={closeModal} />
            <Check className={style.check} />
            <motion.h2
              className={style.modal__title}
              initial={{
                opacity: 0,
                y: -400,
                scale: 0.5,
              }}
              animate={{
                opacity: 1,
                y: 0,
                scale: 1,
                transition: { duration: 0.5 },
              }}
            >
              {t("modal-title")}
            </motion.h2>
            <motion.p
              className={style.modal__descr}
              initial={{
                opacity: 0,
                y: 400,
                scale: 0.5,
              }}
              animate={{
                opacity: 1,
                y: 0,
                scale: 1,
                transition: { duration: 0.6 },
              }}
            >
              {t("modal-text")}
            </motion.p>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default Modal;

import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Main from "./pages/Main";
import "./i18n/i18n";
import { Suspense } from "react";
import Loader from "./components/Loader";

const App = () => {
  const [isAnimating, setIsAnimating] = useState(false);

  const onLanguageChange = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setIsAnimating(false);
    }, 700);
  };

  return (
    <>
      <Suspense fallback={<Loader />}>
        <AnimatePresence>
          {isAnimating && (
            <motion.div
              key="black-screen"
              initial={{ opacity: 0.8, x: "100%" }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0.8, x: "-100%" }}
              transition={{
                duration: 0.5,
                ease: [0.16, 1, 0.3, 1],
                delay: 0.1,
              }}
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "#1c1c1c",
                zIndex: 9999,
              }}
            />
          )}
        </AnimatePresence>
        <Main onLanguageChange={onLanguageChange} />
      </Suspense>
    </>
  );
};

export default App;

import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
import style from "./top.module.scss";

const TopButton = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Link to="top" smooth={true} duration={500}>
      <motion.div
        onClick={scrollToTop}
        className={style.top}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <svg
          viewBox="0 0 32 32"
          xmlns="http://www.w3.org/2000/svg"
          className={style.svg}
        >
          <path d="M15.997 13.374l-7.081 7.081L7 18.54l8.997-8.998 9.003 9-1.916 1.916z" />
        </svg>
      </motion.div>
    </Link>
  );
};

export default TopButton;

import * as Yup from "yup";

import { useTranslation } from "react-i18next";

const Schema = () => {
  const { t } = useTranslation();

  return Yup.object().shape({
    name: Yup.string()
      .required(t("error-name-req"))
      .min(3, t("error-name-min"))
      .matches(/^([а-яёa-z]+\s)*[а-яёa-z]+$/iu, t("error-name-match")),
    email: Yup.string()
      .required(t("error-mail-req"))
      .matches(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        t("error-mail-match")
      ),
  });
};

export default Schema;
